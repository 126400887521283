.graph {
  height: 75vh;
  margin-left: 5px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px dotted gray;
}

.graph-legion {
  z-index: 5;
  position: absolute;
  bottom: 11vh;
  right: 3vh;
  width: 200px;
  padding: 5px;
  overflow: hidden;
  background: #ffffff8f;
}

.property-pannel {
  display: flex;
  z-index: 6;
  position: absolute;
  /* bottom: 5vh; */
  /* right: 0.5vh; */
  right: 5px;
  /* margin-right: 10px; */
  width: 22vw;
  /* padding: 5px; */
  background: #ffffff8f;
  max-height: 75vh;
  flex-wrap: wrap;
}
.expand-collapse {
  display: "flex";
  justify-content: "center";
  align-items: "center";
}
.graph-legion ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.graph-legion ul li {
  padding: 7px 2px;
  border-radius: 2px;
  text-align: center;
  font-size: 10px;
  color: black;
  font-weight: bold;
  border: 1px solid gray;
  margin: 2px;
  background: white;
  display: flex;
  align-items: center;
}

.graph-legion ul li span {
  display: inline-block;
  height: 12px;
  width: 130px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.graph-legion ul li .color-palette {
  height: 20px;
  width: 50px;
  display: inline-block;
  border-radius: 3px;
  margin: 0 5px;
}

.elemental-node {
  font-size: 15px;
  box-sizing: border-box;
  color: black;
  padding: 4px 5px;
  border-radius: 10px;
  height: 50px;
  width: 210px;
  font-weight: bold;
  text-align: center;
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}
.elemental-node--hover {
  display: none;
  position: absolute;
  background: black;
  padding: 5px;
  left: 20px;
  top: 0;
  width: max-content;
  height: 20px;
  z-index: 99;
}
.elemental-node:hover {
  filter: brightness(120%);
}
.elemental-node:hover .elemental-node--hover {
  display: block;
}

.rd3t-link {
  color: red;
  stroke: transparent;
  fill: none;
}

.link__to-branch {
  color: yellowgreen;
  stroke: brown;
  fill: aquamarine;
  stroke-width: 5px;
}

.link__to-leaf {
  fill: green;
}
