* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif !important;
}

.navbar {
  background-color: #fff;
}

.navbar-brand {
  font-size: 1.9rem !important;
  color: #3498db !important ;
}

.navbar a {
  font-size: 1.3rem !important;
  /* text-transform: capitalize; */
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}
.navbar a:hover {
  color: #484848 !important;
}
@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

.brand-name {
  color: #3498db;
}

/* Main Landing Section */

#header {
  width: 100%;
  height: max-content;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px;
  font-size: 48px;
  font-weight: 700;
  color: #222;
  font-family: sans-serif !important;
  font-weight: 400;
  font-style: normal;
  line-height: 1.1em;
  font-size: 52px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
}

#header .brand-name {
  color: #222 !important;
  font-family: sans-serif !important;
  font-weight: 400;
  font-style: normal;
  line-height: 1.1em;
  font-size: 52px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
}

#header h2 {
  color: #222 !important;
  font-family: sans-serif !important;
  font-weight: 500;
  font-style: normal;
  line-height: 1.5em;
  font-size: 18px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 2px;
}

#header .btn-get-started {
  letter-spacing: 1px !important;
  display: inline-block !important;
  padding: 10px 30px !important;
  /* border-radius: 50px; */
  transform: 5s;
  color: #fff !important;
  border: 2px solid #272727 !important;
  text-decoration: none;
  background: #272727 !important;
  font-family: sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
  /* text-transform: uppercase !important; */
  letter-spacing: 1px !important;
}
#header .btn-get-started:hover {
  background: #272727;
  color: #272727;
}

.header-img {
  text-align: right;
}

@media (max-width: 991) {
  #header {
    height: 80vh;
    text-align: center;
  }
  #header .header-img {
    text-align: center;
  }
  #header .header-img img {
    width: 60%;
  }
}
@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }
  #header h1 {
    font-size: 28px;
  }
  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  #header .header-img img {
    width: 70%;
  }
}

@media (min-width: 1px) and (max-width: 767px) {
  #header .header-img {
    text-align: center;
  }
}

/* Hide Edit Pannel Tool & watermark from BPMN Model*/
.djs-palette.open,
a.bjs-powered-by {
  display: none;
}

/* Testing */

.domain {
  color: #3498db;
}

.scenrio {
  color: #484848;
}

.process {
  color: blueviolet;
}

/* BPMN CSS */
.objectName {
  text-align: center;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Table CSS */
.table-data-value,
.attribute-value {
  text-align: right;
}
.attribute-value,
.attribute-name {
  color: #1776bf;
}

/* Property icon */
svg.bi.bi-info-square-fill:hover {
  color: #2b618e;
}

/*main button */

.btn {
  border-radius: 0 !important;
}

/*Drop Down button color */
.btn-success {
  background-color: #272727 !important ;
  border-color: #272727 !important;
  color: #fff !important;
  font-family: sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important	;
  /* text-transform: uppercase !important; */
  letter-spacing: 1px !important;
}

/*attribute panel */
#collapse1 {
  position: absolute;
  right: 0;
  width: 380px;
  height: 300vh;
}

/* background color of left side folder hierarchy */
.left-side-tree-hierarchy {
  background-color: #dddddd;
}

/*Links color*/
a {
  color: #000 !important;
  /* border-bottom: 1px solid #000; */
  border-color: rgba(0, 0, 0, 0.2);
}

.parentDivDomain {
  display: flex;
}

/* Domain Page */
.leftPanel {
  width: 390px; /* or whatever width you want. */
  max-width: 390px; /* or whatever width you want. */
  display: inline-block;
  flex: 0 0 50%;
}
.domains.my-5.text-center {
  flex: 0 0 50%;
}

.bpmnDiv {
  flex: 0 0 45%;
  margin-top: 44px;
  margin-left: -80px;
}

.collapseDiv {
  position: fixed;
  width: 400px;
  height: 400px;
  right: auto;
}
tr:nth-child(even) {
  background-color: #dddddd;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  float: center;
  margin-left: 600px;
  margin-top: 50px;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 120px;
  height: 700px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
}

/*Domain page buttons*/

.btn-primary {
  background-color: #272727 !important ;
  width: 300px;
  border: 2px solid #2b618e !important;
  font-family: sans-serif !important;
  color: #fff !important;
  font-weight: bold !important;
}

/*Navbar background color*/
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background-color: white !important;
}

/*BPMN container*/
.bjs-container {
  margin-top: 10px;
}

/*i/property panel & full screen icons*/
.panel-group {
  margin-left: 5px !important;
}

/* Cross refrence link CSS*/
.content {
  position: relative;
}
#right-panel-link {
  position: absolute;
  top: -50px;
  right: -62%;
  background-color: lightblue !important;
}

/*White color on full screen*/
*:fullscreen,
*:-webkit-full-screen,
*:-moz-full-screen {
  background-color: white;
}

/*my-account page CSS */
.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 70%;
  height: 100%;
}
.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h5 {
  color: #333;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #0062cc;
}

/*Buttons signup, login */

button.w-100.btn.btn-primary {
  font-family: sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* for canvas style [start]*/
.AppCanvas {
  margin: 30px;
}

.grid-wrapper {
  display: inline-flex;
  height: 100vh;
}
.listArea {
  width: 9vh;
  margin-left: 1vh;
  background-color: rgb(221, 221, 221);
  height: 74vh;
}

.target {
  position: relative;
}

.btnLeadTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 6vh;
  right: 0vh !important;
  width: 19vh !important;
}
.orderTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 12vh;
  right: 0vh !important;
  /* width: 31vh !important; */
  width: 19vh !important;
}
.afterSales {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 18vh;
  white-space: nowrap;
  right: 0vh !important;
  width: 19vh !important;
}
.ideaTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 18vh;
  white-space: nowrap;
  right: 50vh !important;
  width: 19vh !important;
}
.forecastTo {
  height: 4vh;
  position: absolute !important;
  top: 24vh;
  right: 20vh !important;
  width: 39vh !important;
}
.procureTo {
  height: 4vh;
  position: absolute !important;
  top: 30vh;
  right: 20vh !important;
  width: 29vh !important;
}
.palnTo {
  height: 4vh;
  position: absolute !important;
  top: 36vh;
  right: 20vh !important;
  width: 19vh !important;
}
.inboundTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 42vh;
  right: 10vh !important;
  width: 39vh !important;
}
.accountTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 48vh;
  right: 0vh !important;
  width: 69vh !important;
  /* border-radius: 15px !important; */
}
.inspectTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 55vh;
  right: 0vh !important;
  width: 69vh !important;
  /* border-radius: 15px !important; */
}
/* project to css removed */
.hireTo {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 60vh;
  right: 0vh !important;
  width: 69vh !important;
  /* border-radius: 15px !important; */
}
.manageToQuality {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 65vh;
  right: 0vh !important;
  width: 69vh !important;
  /* border-radius: 15px !important; */
}
.manageToActivate {
  /* height: 6vh; */
  height: 4vh;
  position: absolute !important;
  top: 70vh;
  right: 0vh !important;
  width: 69vh !important;
  /* border-radius: 15px !important; */
}

div#container {
  margin-top: 5px !important;
}

.tab-content {
  margin-top: 13px !important;
}

.MuiTabs-flexContainer {
  color: black;
  background-color: white;
}
span.MuiTab-wrapper {
  font-weight: bold;
}
.fade.alert.alert-success.show {
  color: black;
  background-color: white;
  border-color: black;
  font-weight: bold;
}
.footerFix {
  margin-top: auto;
}
.containerMain {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}
/* for navbar style [start]*/
.nav-item {
  background-color: #4444 !important;
}
* .nav-tabs {
  border-color: #dddddd !important;
  border-bottom: 0px solid #dddddd !important;
}
.nav-link.active {
  border-color: #dddddd !important;
}
.nav-link.active {
  background-color: #dddddd !important;
  font-weight: bold;
}

#collapse1 > ul > li > a.active {
  font-weight: bold !important;
  text-decoration: "underline" !important;
}

.factSheetFullName:hover {
  text-decoration: underline;
}

.disableSelection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
}

.pagination {
  justify-content: center;
}
